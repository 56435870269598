import glide from "@glidejs/glide";
import Splide from "@splidejs/splide";
import { cookiePolicy } from "./cookiePolicy";

// find #homepage-slider
const slider = document.querySelector("#homepage-slider");
if (slider) {
    // init glide
    new glide(slider, {
        type: "carousel",
        startAt: 0,
        perView: 1,
        autoplay: 5000,
        hoverpause: true,
        animationDuration: 250,
        animationTimingFunc: "ease-in-out",
        dragThreshold: false
    }).mount();
}

const testimonialsSlider = document.querySelector("#testimonials-slider");

if (testimonialsSlider) {
    new glide(testimonialsSlider, {
        type: "carousel",
        startAt: 0,
        perView: 3,
        autoplay: 5000,
        hoverpause: true,
        animationDuration: 250,
        animationTimingFunc: "ease-in-out",
        dragThreshold: false,
        gap: 20,
        breakpoints: {
            768: {
                perView: 1
            }
        }
    }).mount();
}

const mainSlider = document.querySelector("#main-slider");
const thumbnailSlider = document.querySelector("#thumbnail-slider");

if (mainSlider && thumbnailSlider) {
    var main = new Splide("#main-slider", {
        type: "fade",
        heightRatio: 0.5,
        pagination: false,
        arrows: true,
        cover: true,
        autoplay: true,
        interval: 5000
    });

        try {
            var thumbnails = new Splide("#thumbnail-slider", {
                rewind: true,
                isNavigation: true,
                fixedHeight: "33%",
                gap: 10,
                focus: "center",
                pagination: false,
                cover: true,
                direction: "ttb",
                heightRatio: 1.12,
                dragMinThreshold: {
                    mouse: 4,
                    touch: 10
                }
            });
        
    

    main.sync(thumbnails);
    main.mount();
    thumbnails.mount();
} catch (error) {}
}

function isHiddenByMediaQuery(el) {
    const style = window.getComputedStyle(el);
    return style.display === "none";
}

//find all a in .linkGridMap
const linkGridMapLinks = document.querySelectorAll(".linkGridMap a");

//loop through all links and find any that have a # in the href
linkGridMapLinks.forEach(link => {
    if (link.href.includes("#")) {
        link.addEventListener("click", e => {
            e.preventDefault();
            const target = document.querySelector(link.getAttribute("href"));
            //scroll to target with an offset of 100px
            window.scrollTo({
                top: target.offsetTop - 200,
                behavior: "smooth"
            });
        });
    }
});

//find all .faq-question elements
const faqQuestions = document.querySelectorAll(".faq-question");
//loop through all faq questions
faqQuestions.forEach(question => {
    //add click event listener
    question.addEventListener("click", e => {
        //find the next sibling
        const answer = question.nextElementSibling;
        //toggle the class
        answer.classList.toggle("open");
    });
});

//find all .videofaq elements

const videoFaqQuestions = document.querySelectorAll(".videofaq");
//loop through all faq questions
videoFaqQuestions.forEach(question => {
    //find the a element inside the question
    const link = question.querySelector("a");

    link.addEventListener("click", e => {
        e.preventDefault();
        var videoURL = link.getAttribute("href");
        createLigtbox(videoURL);
    });
});

document.querySelectorAll("figure.table table").forEach(table => {
    table.classList.add("table", "table-bordered", "table-striped");
})


function createLigtbox(videoURL) {
    const lightbox = document.createElement("div");
    lightbox.classList.add("lightbox");
    lightbox.innerHTML = `<button class="lightbox-close"><i class="fas fa-times"></i></button>`;
    lightbox.innerHTML += `<iframe width="560" height="315" src="${videoURL}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
    document.body.appendChild(lightbox);
    //find the button in the dom
    const closeButton = document.querySelector(".lightbox-close");
    closeButton.addEventListener("click", e => {
        lightbox.classList.remove("open");
        lightbox.parentElement.removeChild(lightbox);
    });
    setTimeout(() => {
        lightbox.classList.add("open");
    }, 10);
}

const mobileMenuButton = document.querySelector(".mobile-menu-button");
const mobileMenu = document.getElementById("mobile-nav");
const MobileMenuClose = document.getElementById("mobile-nav-close");
mobileMenuButton.addEventListener("click", e => {
    e.preventDefault();
    mobileMenu.classList.toggle("open");
});

MobileMenuClose.addEventListener("click", e => {
    e.preventDefault();
    mobileMenu.classList.toggle("open");
});

cookiePolicy(() => {

});